import { APICore } from "./apiCore";
const api = new APICore();

function createClaim(data: any) {
    const baseUrl = `/claim/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchClaims(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string, status?: string, origin?: string) {
    const baseURL = `/claim/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    if (status) params.append('status', status);
    if (origin) params.append('origin', origin);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchClaimById(id: number) {
    const url = `/claim/${id}`;
    return api.get(url, "");
}

function editClaim(id: number, data: any) {
    const url = `/claim/${id}`;
    return api.update(url, data);
}

function fetchExpenseTypes() {
    let baseUrl = `/application/metadata?id=12`;
    return api.get(`${baseUrl}`, "");
}

export { createClaim, fetchClaimById, fetchClaims, editClaim, fetchExpenseTypes };
